@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-slide{
  transform: scale(0.9);
}
.swiper-slide.swiper-slide-active {
  transition: 0.3s ease;
  transform: scale(1);
}
.swiper-slide.swiper-slide-active img{
  border-radius: 6;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev,
.swiper-button-prev, 
.swiper-container-rtl .swiper-button-next{
    font-family: none;
}

.swiper-button-prev{
  padding-left: 40px;
}
.swiper-button-next{
  padding-right: 40px;
}
